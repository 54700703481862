<template>
  <v-app-bar
    app
    color="primary"
    clipped-left
    dark
  >
    <v-progress-linear
      :active="loading"
      color="accent"
      indeterminate
      fixed
      top
    />
      <!-- v-if="mobile && loggedIn" -->
    <v-app-bar-nav-icon
      v-show="showDialogToggleButton"
      @click="$emit('toggle')"
    />

    <v-toolbar-title>
      <v-btn class="text-h4 flat-btn" plain text :to="{name: 'logs'}">
        {{ labels.title }}
      </v-btn>
    </v-toolbar-title>

    <tooltip-button
      bottom
      icon="upload"
      text="BE"
      :to="{name: 'uploadBE'}"
      :tooltip="labels.uploadBE"
    />
    <tooltip-button
      bottom
      icon="upload"
      text="FE"
      :to="{name: 'uploadFE'}"
      :tooltip="labels.uploadFE"
    />
    <tooltip-button
      bottom
      icon="logs"
      text="Logs"
      :to="{name: 'logs'}"
      :tooltip="labels.logs"
    />

    <v-spacer />
    <v-chip
      v-if="showEnvironmentButton"
      dark
      color="accent"
      label
      :to="{
        name: 'environment',
        params: { 'name': environment.name, }
      }"
    >
      <v-icon left v-text="'$home'" />
      {{ environment.name }}
    </v-chip>
    <v-spacer />

    <refresh-button />
    <tooltip-button
      bottom
      icon="dark"
      :tooltip="`Switch to ${ dark ? 'light' : 'dark'} mode`"
      :action="toggleDark"
    />
    <tooltip-button
      v-if="admin"
      bottom
      icon="admin"
      :href="adminUrl"
      :tooltip="labels.admin"
    />
    <tooltip-button
      bottom
      icon="logout"
      :action="logout"
      :tooltip="labels.logout"
    />
  </v-app-bar>
</template>

<script>
import { get, isNull } from 'lodash';
import { mapState } from 'vuex';
import { labels } from '@/assets/texts.json';

export default {
  name: 'appBar',
  components: {
    refreshButton: () => import('@/components/refreshButton.vue'),
    tooltipButton: () => import('@/components/tooltipButton.vue'),
  },
  props: {
    showDialogToggleButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('fetch', [
      'environment',
    ]),
    ...mapState('shared', [
      'loading',
    ]),
    admin() {
      const maatRoles = get(
        this.$keycloak.resourceAccess,
        `${this.$maatRole}.roles`,
        {}
      );
      return maatRoles.includes('admin');
    },
    adminUrl() {
      return `${this.$maatUrl}/devops`;
    },
    dark() {
      return this.$vuetify.theme.isDark;
    },
    showEnvironmentButton() {
      return !isNull(this.environment) && this.$route.name !== 'environment';
    },
  },
  data: () => ({
    labels,
  }),
  methods: {
    logout() {
      this.$emit('logout');
    },
    toggleDark() {
      this.$emit('dark');
    },
  },
};
</script>

<style scoped>
  .flat-btn {
    text-transform: none;
  }
  .v-toolbar__title {
    border-right: solid white 2px;
  }
</style>
